import React, { useEffect } from "react";
import { Typography, Space, Divider } from "antd";
import Header from "../Header";
import Footer from "../Footer";

const { Title, Paragraph, Text } = Typography;

const PressRelease = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div
        style={{
          maxWidth: "800px",
          margin: "120px auto 60px",
          padding: "0 24px",
        }}
      >
        <Typography>
          {/* Keep title and subtitle centered */}
          <Title
            level={1}
            style={{
              fontSize: "32px",
              color: "#090d4e",
              marginBottom: "32px",
              textAlign: "center",
            }}
          >
            SITIN.ai Raises $4.5M Seed Funding to Transform Online Meetings
          </Title>

          <Paragraph
            style={{
              fontSize: "18px",
              color: "#090d4e",
              fontWeight: 500,
              marginBottom: "48px",
              textAlign: "center",
            }}
          >
            Empowering professionals with digital twins that attend meetings,
            take notes, and even speak on your behalf
          </Paragraph>

          {/* Main content left-aligned */}
          <div style={{ textAlign: "left" }}>
            <Paragraph style={{ fontSize: "16px", lineHeight: 1.8 }}>
              SITIN.ai, the innovative platform that creates a lifelike digital
              twin to represent you in online meetings, announced today the
              close of a $4.5 million seed funding round. The round was led by
              Makers Fund, with participation from other renowned US dollar
              venture funds. This capital will drive the next phase of product
              development and global market expansion.
            </Paragraph>

            <Title
              level={2}
              style={{ fontSize: "24px", color: "#090d4e", marginTop: "48px" }}
            >
              Reinventing the Online Meeting Experience
            </Title>

            {/* Rest of the content remains the same but will be left-aligned due to parent div */}
            <Paragraph style={{ fontSize: "16px", lineHeight: 1.8 }}>
              In today's video-first world, endless meetings can drain
              productivity. SITIN.ai's breakthrough technology enables
              professionals to delegate meeting attendance to a digital twin
              that not only clones your appearance and presence but also
              actively interacts—recording notes, tracking tasks, and even
              speaking on your behalf when needed. Currently in closed beta,
              SITIN.ai is designed to help users reclaim valuable time and focus
              on high-impact work.
            </Paragraph>

            <Paragraph
              style={{
                fontSize: "16px",
                lineHeight: 1.8,
                background: "rgba(255, 255, 255, 0.5)",
                padding: "24px",
                borderRadius: "12px",
                margin: "32px 0",
              }}
            >
              "Traditional meetings can be a major time sink," said Max Zhou,
              CEO of SITIN.ai. "Our digital twin technology changes the game by
              ensuring that you never miss a key moment—even if you're not
              physically present. With this funding, we're accelerating our
              efforts to bring a more efficient, human-centered meeting
              experience to professionals around the globe."
            </Paragraph>

            <Title level={2} style={{ fontSize: "24px", color: "#090d4e" }}>
              Strategic Priorities
            </Title>
            <Paragraph style={{ fontSize: "16px", lineHeight: 1.8 }}>
              The new funding will support several key initiatives:
            </Paragraph>
            <ul
              style={{
                fontSize: "16px",
                lineHeight: 1.8,
                marginLeft: "24px",
                marginBottom: "32px",
              }}
            >
              <li>
                Enhancing Core Technology: Further develop the AI behind digital
                twin realism and interactive communication.
              </li>
              <li>
                Beta Expansion and Product Refinement: Expand our closed beta
                program to gather real-world insights and optimize user
                experience.
              </li>
              <li>
                Global Market Penetration: Build strategic partnerships and
                scale our go-to-market efforts worldwide.
              </li>
            </ul>

            <Title level={2} style={{ fontSize: "24px", color: "#090d4e" }}>
              Investor Insights
            </Title>
            <Paragraph
              style={{
                fontSize: "16px",
                lineHeight: 1.8,
                background: "rgba(255, 255, 255, 0.5)",
                padding: "24px",
                borderRadius: "12px",
                margin: "32px 0",
              }}
            >
              "SITIN.ai is redefining how professionals manage their online
              interactions by leveraging digital twin technology," said Jiang,
              Investor at Makers Fund. "We're excited to back a solution that
              not only automates meeting tasks but also empowers users to take
              control of their time and productivity."
            </Paragraph>

            <Title level={2} style={{ fontSize: "24px", color: "#090d4e" }}>
              Looking Forward
            </Title>
            <Paragraph style={{ fontSize: "16px", lineHeight: 1.8 }}>
              As SITIN.ai continues to refine its platform, it remains committed
              to revolutionizing the remote meeting landscape. With the support
              of leading investors, the company is poised to launch its public
              product in the coming months and transform the way organizations
              handle virtual meetings.
            </Paragraph>
          </div>
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default PressRelease;
