import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../App";
import Header from "../Header";
import Footer from "../Footer";

const Subscribe = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const colletionRef = collection(db, "emails");

      const newEmail = {
        email: values.email,
        createdAt: new Date().toISOString(),
      };

      console.log("Attempting to write to Firestore:", newEmail);

      try {
        const emailRef = doc(colletionRef, values.email);
        await setDoc(emailRef, newEmail);
        console.log("Successfully wrote to Firestore");
        window.collectEvent("subscriber_email_added");

        message.config({
          top: 120, // Adjust this value to position the message below the header
        });

        message.success({
          content:
            "Thank you for signing up! We will notify you about testing opportunities.",
          duration: 3,
        });

        form.resetFields();
      } catch (firestoreError) {
        console.error("Firestore write error:", firestoreError);
        throw firestoreError;
      }
    } catch (error) {
      console.error("Error submitting subscriber email:", error);
      message.error(`Error submitting form: ${error.message}`);
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          maxWidth: 600,
          margin: "120px auto 60px",
          padding: "32px 16px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Join the waitlist</h2>
          <div style={{ marginBottom: 24 }}>
            Leave your email to receive notifications about our internal testing
            opportunities.
          </div>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            style={{ textAlign: "left" }}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input size="large" placeholder="Email Address" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" size="large">
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Subscribe;
