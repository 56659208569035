import { Button, message } from "antd";
import { fbqDownloadEvent } from "./facebookPixelEvents";
import { useNavigate } from "react-router-dom";

const SubscribeButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/subscribe");
  };

  return (
    <Button onClick={handleClick} className="download-button">
      Join the waitlist
    </Button>
  );
};

export default SubscribeButton;
