import React from "react";
import FooterLogin from "./FooterLogin";
import { useNavigate } from "react-router-dom";
const sitinLogo = require("./assets/sitin-logo.png");

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer id="footer">
      <div className="footer-content">
        <div className="footer-left">
          <img id="footer-logo" src={sitinLogo} alt="SITIN logo" />
          <div id="footer-desc">
            Intelligent AI representation for your virtual meetings.
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-column">
            <h4>Product</h4>
            <ul>
              <li>
                <span
                  onClick={() => navigate("/subscribe")}
                  style={{ cursor: "pointer" }}
                >
                  Request Early Access
                </span>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>News</h4>
            <ul>
              <li>
                <span
                  onClick={() => navigate("/press-release")}
                  style={{ cursor: "pointer" }}
                >
                  SITIN.ai Raises Seed Round
                </span>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Support</h4>
            <ul>
              <li>
                <span
                  onClick={() =>
                    (window.location.href = "mailto:feedback@sitin.ai")
                  }
                  style={{ cursor: "pointer" }}
                >
                  Support
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    (window.location.href =
                      "https://sitin.ai/privacy-policy.html")
                  }
                  style={{ cursor: "pointer" }}
                >
                  Privacy Policy
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="footer-bottom">
        <div className="footer-copyright">
          © 2024 SITIN.ai | All rights reserved
        </div>
      </div> */}
      <FooterLogin />
    </footer>
  );
};

export default Footer;
