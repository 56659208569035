import React, { useState } from "react";
import { Button, Menu } from "antd";
import LoggedInStateDisplay from "./LoggedInStateDisplay";
import EmailLogin from "./EmailLogin";
import GoogleLogin from "./GoogleLogin";
import DownloadSitinAppButton from "./DownloadSitinAppButton";
import { Link } from "react-router-dom";
import SubscribeButton from "./SubscribeButton";

const sitinLogo = require("./assets/sitin-logo.png");

// const items = [
//   {
//     label: "Home",
//     key: "home",
//   },
//   {
//     label: "About",
//     key: "about",
//   },
//   {
//     label: "Contact Us",
//     key: "contact",
//   },
// ];

const Header = () => {
  // const [current, setCurrent] = useState("home");

  // const onClick = (e) => {
  //   console.log("click ", e);
  //   setCurrent(e.key);
  // };

  return (
    <div id="header">
      <Link to="/">
        <img id="sitin-logo" src={sitinLogo} alt="SITIN logo" />
      </Link>
      {/* <Menu
        className="ant-menu"
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        style={{ flex: "1 auto", borderBottom: "none" }}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} className="ant-menu-item">
            {item.label}
          </Menu.Item>
        ))}
      </Menu> */}
      <span style={{ flex: "1 auto", textAlign: "right" }}>
        {/* <EmailLogin /> */}
        {/* <AppleLoginButton /> */}
        <div id="header-download-button-wrapper">
          {/* <LoggedInStateDisplay />
          <GoogleLogin /> */}
          {/* <DownloadSitinAppButton /> */}
          <SubscribeButton />
        </div>
      </span>
    </div>
  );
};

export default Header;
